import React from "react";

export default function Heart(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 324.35 285.08"
      {...props}
    >
      <path d="M322.72,72a79.39,79.39,0,0,0-17-38.34c-11.91-15-27-25.34-45.54-30.43a85.81,85.81,0,0,0-40.55-1.42c-14.24,2.92-27.12,8.66-38.12,18.14-6,5.21-11.57,11-17.22,16.67-1.6,1.61-2.72,1.72-4.31.06-3.41-3.55-7-6.94-10.43-10.43A85.33,85.33,0,0,0,103.63,1.62,86.49,86.49,0,0,0,63.08,3.34,85,85,0,0,0,17,35.39C1.27,56.91-3.48,80.84,2.49,106.67,6.5,124,15.29,139,27.85,151.56Q93.94,217.83,160.12,284c1.7,1.7,2.57,1.19,4-.22q65.79-65.88,131.62-131.73c17.57-17.68,27.79-38.83,28.63-64.21C323.82,82.56,323.49,77.24,322.72,72Z" />
    </svg>
  );
}
